<template>
  <div class="dog-top-actions">
    <el-tooltip effect="dark" content="Add Registration" placement="left">
      <font-awesome-icon
        :icon="['fa', 'plus-circle']"
        @Click="openDialogue"
      ></font-awesome-icon>
    </el-tooltip>
  </div>
  <div v-if="getRegistrations.length > 0">
    <DogRegistration
      v-for="registration in getRegistrations"
      :key="registration.itemId"
      :registration="registration"
      :removeRegistration="removeRegistration"
    />
  </div>
  <div v-else>
    <h3>No registrations at this time.</h3>
  </div>

  <el-dialog title="Add Registration" :modelValue="dialogueVisible" width="40%">
    <base-spinner v-if="isUpdating"></base-spinner>
    <el-form
      v-else-if="!isUpdating"
      ref="addRegistrationForm"
      :model="addRegistrationForm"
      :rules="formRules"
      label-position="top"
    >
      <el-form-item prop="venue">
        <el-input
          placeholder="Venue (i.e. USDAA, AKC, etc.)"
          v-model="addRegistrationForm.venue"
        ></el-input>
      </el-form-item>
      <el-form-item prop="registrationNumber">
        <el-input
          placeholder="Number"
          v-model="addRegistrationForm.registrationNumber"
        ></el-input>
      </el-form-item>
    </el-form>
    <h3 v-else>Something went wrong</h3>
    <span class="buttons">
      <base-button color="secondary" @click="addRegistration"
        >Add Registration</base-button
      >
      <base-button color="tertiary" @click="closeDialogue">Cancel</base-button>
    </span>
  </el-dialog>
</template>

<script>
import DogRegistration from "../components/dog/DogRegistration.vue";
import { uuid } from "vue-uuid";

export default {
  components: { DogRegistration },
  data() {
    return {
      isUpdating: false,
      dialogueVisible: false,
      addRegistrationForm: {
        itemId: uuid.v4(),
        venue: "",
        registrationNumber: "",
      },
      formRules: {
        venue: [
          { required: true, message: "Please enter venue", trigger: "blur" },
        ],
        registrationNumber: [
          {
            required: true,
            message: "Please enter registration number",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    getDogId() {
      return this.$store.getters["dog/getDog"]._id;
    },
    getRegistrations() {
      return this.$store.getters["dog/getDog"].registrations;
    },
  },
  methods: {
    openDialogue() {
      this.dialogueVisible = true;
    },
    closeDialogue() {
      this.dialogueVisible = false;
      this.resetForm();
    },
    async addRegistration() {
      this.$refs["addRegistrationForm"].validate(async (valid) => {
        if (valid) {
          try {
            this.isUpdating = true;
            let updatedDogRegistrations = [...this.getRegistrations];

            updatedDogRegistrations.push(this.addRegistrationForm);
            await this.$store.dispatch("dog/updateDog", {
              dogId: this.getDogId,
              update: { registrations: updatedDogRegistrations },
            });

            this.$message({
              showClose: true,
              message: `${this.addRegistrationForm.venue} added`,
              type: "success",
              duration: 5000,
            });
            this.isUpdating = false;
            this.dialogueVisible = false;
            this.resetForm();
          } catch (error) {
            console.log(error);
            this.$message({
              showClose: true,
              message: "Error DRV1000 adding registration",
              type: "error",
              duration: 5000,
            });
          }
        } else {
          console.log("error submitting!!");
          return false;
        }
      });
    },
    removeRegistration(registrationToRemove) {
      const dogRegistrations = [...this.getRegistrations];

      this.$confirm(
        `Are you sure you want to remove ${registrationToRemove.venue}`,
        "Warning",
        {
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "warning",
        }
      )
        .then(async () => {
          try {
            const updatedDogRegistrations = dogRegistrations.filter(
              (registration) =>
                registration.itemId !== registrationToRemove.itemId
            );

            await this.$store.dispatch("dog/updateDog", {
              dogId: this.getDogId,
              update: { registrations: updatedDogRegistrations },
            });

            this.$message({
              showClose: true,
              message: `${registrationToRemove.venue} removed`,
              type: "success",
              duration: 5000,
            });
          } catch (error) {
            console.log(error);
            this.$message({
              showClose: true,
              message: "Error DRV1100 removing registration",
              type: "error",
              duration: 5000,
            });
          }
        })
        .catch(() =>
          this.$message({
            type: "info",
            message: "Remove Cancelled",
          })
        );
    },
    resetForm() {
      this.addRegistrationForm = {
        itemId: uuid.v4(),
        venue: "",
        registrationNumber: "",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.el-input {
  margin-bottom: 10px;
}

.buttons {
  display: block;

  button {
    margin: 10px 10px 0 0;
  }
}

.dog-top-actions {
  margin-bottom: 15px;
}
</style>
