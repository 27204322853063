<template>
  <div class="registration-wrapper">
    <p class="venue">{{ registration.venue }}</p>
    <p class="registration-number">{{ registration.registrationNumber }}</p>
    <font-awesome-icon
      :icon="['fa', 'minus-circle']"
      @click="removeRegistration(registration)"
    ></font-awesome-icon>
  </div>
</template>

<script>
export default {
  props: { registration: Object, removeRegistration: Function },
};
</script>

<style lang="scss" scoped>
.registration-wrapper {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: center;

  svg:hover {
    cursor: pointer;
    color: $secondary;
  }

  p {
    margin: 0 5px 5px 0;
    padding: 10px 20px;
    width: 150px;
    background: #fff;
    border: 1px solid $tertiary-25;
  }

  p.venue {
    font-weight: bold;
    border-radius: 5px 0 0 5px;
  }

  p.registration-number {
    color: $primary;
    text-align: center;
    border-radius: 0 5px 5px 0;
  }
}
</style>
